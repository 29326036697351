import parseMoney from '../helpers/parse-money'
import significantDigits from '../helpers/significant-digits'
import { getState, reduce, multiply, createSelector } from './shared'
import { getAllItems, getItem, getItemLabourItems, getItemMaterialItems } from './items'
import { getAllSections } from './sections'
import { calculateLabourItemCisTotal, calculateLabourItemTotal, getAllLabourItems } from './labour-items'
import { calculateMaterialItemTotal } from './material-items'

/**
 * Returns an object containing all paperwork in the app.
 * @param {object} state - The current state of the app.
 * @returns {object} - An object containing all paperwork in the app.
 * @private
 */
export const getAllPaperwork = state => state.paperwork
export const getPaperwork = (state, paperworkUUID) => getAllPaperwork(state)[paperworkUUID]

/**
 * @param {object} state - The current state of the app.
 * @param {string} paperworkUUID - The UUID of the paperwork to get items for.
 * @returns {array} - An array of all items associated with the specified paperwork.
 * @private
 */
const getPaperworkItems = createSelector(
  getPaperwork,
  getAllItems,
  (paperwork, items) => Object.values(
    items
  ).filter(item => paperwork.items.includes(item.uuid))
)

export const getPaperworkItemsWithMultipleQuantity = createSelector(
  getPaperworkItems,
  items => items.filter(item => item.quantity > 1)
)

export const getPaperworkDiscountPercentages = createSelector(
  getPaperworkItems,
  items => items.map(item => item.discount)
)

/**
 * @param {object} state - The current state of the app.
 * @param {string} paperworkUUID - The UUID of the paperwork to get sections for.
 * @returns {array} - An array of all sections associated with the specified paperwork.
 */
export const getPaperworkSections = createSelector(
  getPaperwork,
  getAllSections,
  ({ sections: sectionUuids }, sections) => Object.values(sectionUuids).map(uuid => sections[uuid])
)

const getPaperworkItemMaterialItemsTotal = createSelector(
  getItemMaterialItems,
  reduce(calculateMaterialItemTotal)
)

const getPaperworkItemLabourItemsTotal = createSelector(
  getItemLabourItems,
  reduce(calculateLabourItemTotal)
)

/**
 * Calculates the discount amount based on the given amount and percentage.
 * @param {number} amount - The amount to apply the discount to.
 * @param {number} percentage - The percentage of the discount.
 * @returns {number} - The discount amount.
 */
function calculateDiscount (amount, percentage) {
  return (amount * (parseMoney(percentage) / 100)) * -1
}

const getPaperworkItemQuantity = createSelector(
  getItem,
  item => {
    if (item.optional) { return 0 }
    return parseMoney(item.quantity)
  }
)

const getPaperworkItemSubTotal = createSelector(
  getItem,
  getPaperworkItemMaterialItemsTotal,
  getPaperworkItemLabourItemsTotal,
  ({ price }, materialItemsTotal, labourItemsTotal) => parseMoney(price) + materialItemsTotal + labourItemsTotal
)

const getPaperworkItemDiscount = createSelector(
  getItem,
  getPaperworkItemSubTotal,
  (item, subTotal) => calculateDiscount(subTotal, parseMoney(item.discount))
)

const getPaperworkItemTax = createSelector(
  getItem,
  getPaperworkItemDiscount,
  getPaperworkItemSubTotal,
  (item, discount, subTotal) => parseMoney((subTotal + discount) * (parseMoney(item.taxRate) / 100.00))
)

const getPaperworkItemLineSubTotal = createSelector(
  getPaperworkItemSubTotal,
  getPaperworkItemQuantity,
  multiply
)

const getPaperworkItemLineDiscount = createSelector(
  getPaperworkItemDiscount,
  getPaperworkItemQuantity,
  multiply
)

const getPaperworkItemLineTax = createSelector(
  getPaperworkItemQuantity,
  getPaperworkItemTax,
  multiply
)

const getPaperworkItemLineTotal = createSelector(
  getPaperworkItemLineSubTotal,
  getPaperworkItemLineTax,
  getPaperworkItemLineDiscount,
  (subTotal, tax, discount) => (subTotal + tax + discount)
)

const getPaperworkTax = createSelector(
  getPaperwork,
  getState,
  ({ items }, state) => (
    items.reduce((acc, itemUUID) => acc + getPaperworkItemLineTax(state, itemUUID), 0)
  )
)

const getPaperworkDiscount = createSelector(
  getPaperwork,
  getState,
  ({ items }, state) => (
    items.reduce((acc, itemUUID) => acc + getPaperworkItemLineDiscount(state, itemUUID), 0)
  )
)

const getPaperworkCisTotal = createSelector(
  getPaperwork,
  getPaperworkItems,
  getAllLabourItems,
  ({ cisRate }, items, labourItems) => items.reduce(
    (total, item) => total + Object.values(labourItems).filter(
      labourItem => item.labourItems.includes(labourItem.uuid)
    ).reduce(
      (itemTotal, labourItem) => itemTotal + (calculateLabourItemCisTotal(labourItem, cisRate) * item.quantity),
      0
    ), 0
  ) * -1
)

const getPaperworkSubTotal = createSelector(
  getPaperwork,
  getState,
  ({ items }, state) => (
    items.reduce((acc, itemUUID) => (
      acc + getPaperworkItemLineSubTotal(state, itemUUID)
    ), 0)
  )
)

const getPaperworkTotal = createSelector(
  getPaperwork,
  getPaperworkTax,
  getState,
  ({ items, vatReverseCharge }, tax, state) => {
    const totalWithTax = items.reduce((acc, itemUUID) => acc + getPaperworkItemLineTotal(state, itemUUID), 0)

    if (vatReverseCharge) {
      return parseMoney(totalWithTax - tax)
    }
    return parseMoney(totalWithTax)
  }
)

const getPaperworkCisTotalPayable = createSelector(
  getPaperworkTotal,
  getPaperworkCisTotal,
  (total, cisTotal) => total + cisTotal
)

const sortByName = (a, b) => (a > b ? 1 : -1)

const getPaperworkTaxTotals = createSelector(
  getPaperworkItems,
  getState,
  (items, state) => {
    const totals = items
      .filter(item => item.taxRate >= 0)
      .reduce((acc, { uuid, taxRate }) => {
        acc[taxRate] = (acc[taxRate] || 0) + getPaperworkItemLineTax(state, uuid)
        return acc
      }, {})
    return Object
      .entries(totals)
      .map(([key, value]) => ({ name: significantDigits(key), total: value }))
      .sort(sortByName)
  }
)

export const paperworkTotals = createSelector(
  getPaperworkSubTotal,
  getPaperworkDiscount,
  getPaperworkTax,
  getPaperworkTotal,
  getPaperworkCisTotal,
  getPaperworkCisTotalPayable,
  getPaperworkTaxTotals,
  (
    subTotal,
    discount,
    tax,
    total,
    cis,
    cisPayable,
    taxes
  ) => ({
    subTotal,
    discount,
    tax,
    total,
    cis,
    cisPayable,
    taxes
  })
)
