import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import axios from '@ytb/axios'

import FileInput from './file-input'
import FileList from './file-list'
import uploader from '../helpers/uploader'
import useBeforeUnload from '../helpers/use-before-unload-hook'

import '@uppy/core/dist/style.css'

const FileUploader = ({
  initialFiles,
  attachableType,
  attachableId,
  sharedByDefault
}) => {
  const [files, setFiles] = useState(initialFiles)
  const [uploading, setUploading] = useState(false)

  const onUploadEnd = file => {
    setFiles(currentFiles => [...currentFiles, file])
    setUploading(false)
  }

  const fileUploader = useMemo(() => (
    uploader({
      fileType: 'file',
      onUploadStart: () => setUploading(true),
      onUploadEnd,
      endpoint: '/attachments',
      params: {
        attachable_id: attachableId,
        attachable_type: attachableType,
        shared: sharedByDefault
      }
    })
  ), [files])

  useEffect(() => () => fileUploader.close)
  useBeforeUnload(uploading)

  const handleDelete = file => {
    axios.delete(`/attachments/${file.id}`)
      .then(() => {
        const filteredFiles = files.filter(f => f.id !== file.id)
        setFiles([...filteredFiles])
      })
  }

  return (
    <div className='file-uploader'>
      <FileInput
        uploader={fileUploader}
        buttonClass='btn__add'
        includeIcon
        uploading={uploading}
      />
      <FileList
        files={files}
        onDelete={handleDelete}
      />
    </div>
  )
}

FileUploader.defaultProps = {
  sharedByDefault: false
}

FileUploader.propTypes = {
  initialFiles: PropTypes.array.isRequired,
  attachableType: PropTypes.string.isRequired,
  attachableId: PropTypes.number.isRequired,
  sharedByDefault: PropTypes.bool
}

export default FileUploader
