/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import axios from '@ytb/axios'
import Field from './field'
import AddInputButton from './add-input-button'

const slice = [].slice

const Input = createReactClass({
  displayName: 'Input',
  propTypes: {
    autosave: PropTypes.bool,
    autofocus: PropTypes.bool,
    for: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.oneOf(['string', 'text', 'richtext', 'bool', 'int', 'float', '[string]', '[text]', '[richtext]', '[bool]', '[int]', '[float]']).isRequired,
    field: PropTypes.string,
    path: PropTypes.string.isRequired,
    initialValue: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object)
    ]),
    placeholder: PropTypes.string,
    options: PropTypes.array,
    shallowRoutes: PropTypes.bool,
    noCreate: PropTypes.bool,
    inputId: PropTypes.string,
    label: PropTypes.string,
    labelPosition: PropTypes.oneOf(['before', 'after']),
    helpers: PropTypes.arrayOf(PropTypes.string),
    class: PropTypes.string,
    labelClass: PropTypes.string,
    wrapperClass: PropTypes.string,
    fieldWrapperClass: PropTypes.string,
    addLabel: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string)
  },
  getDefaultProps () {
    return {
      type: 'text',
      initialValue: '',
      label: undefined,
      labelPosition: 'before',
      helpers: [],
      shallowRoutes: false,
      noCreate: false,
      class: '',
      errors: []
    }
  },
  getInitialState () {
    const { errors, initialValue, path } = this.props
    let i
    let item
    let j
    let len
    if (initialValue instanceof Array) {
      for (i = j = 0, len = initialValue.length; j < len; i = ++j) {
        item = initialValue[i]
        item.componentId = i
      }
    }
    return {
      path,
      value: initialValue,
      errors
    }
  },
  array_type_regex: /^\[([a-z_]+)]$/,
  arrayType () {
    const { type } = this.props
    return type.match(this.array_type_regex)
  },
  boolean () {
    const { type } = this.props
    return type === 'bool' || type === '[bool]'
  },
  classes () {
    const { class: className } = this.props
    return className.split(' ').concat('input').join(' ')
  },
  wrapperClasses () {
    const { field, wrapperClass } = this.props
    const { value } = this.state
    let classes = (wrapperClass || '').split(' ')
    classes = classes.concat('frm__block')
    if (value instanceof Array) {
      classes = classes.concat('frm__list-of-inputs')
    }
    return classes.join(' ')
  },
  model () {
    const { for: forName } = this.props
    return forName.split('.')[0]
  },
  attribute () {
    const { for: forName } = this.props
    return forName.split('.')[1]
  },
  boolModel () {
    if (this.boolean()) {
      return this.model()
    }
  },
  pathFor (id) {
    const { path, shallowRoutes } = this.props
    let tree
    if (id == null || path.match(/company|user/)) {
      return path
    }
    tree = path.split('/')
    if (shallowRoutes) {
      tree = ['', tree[tree.length - 1]]
    }
    return tree.concat(id).join('/')
  },
  autosave () {
    const { autosave, options, type } = this.props
    if (autosave != null) {
      return autosave
    }
    return (options != null) || !type.match(/^\[?(string|text|richtext)\]?$/)
  },
  sendableValue (target) {
    if (!this.boolean()) {
      return target.value.replace(/\r\n|\n|\r/g, '\r\n')
    }
    if (target.checked) {
      return 1
    }
    return 0
  },
  userEditedValue (childComponent) {
    return (_this => event => {
      if (typeof event !== 'string') {
        event.persist()
      }

      _this.abortSave()
      if (_this.autosave()) {
        return _this.saver = setTimeout(() => {
          _this.userEditedValueSynchronous(event, childComponent)
          return _this.saver = null
        }, _this.autosaveDelay())
      }
      return childComponent.setState({
        status: 'save',
        saver () {
          return _this.userEditedValueSynchronous(event, childComponent)
        }
      })
    })(this)
  },
  abortSave () {
    if (this.saver) {
      return clearTimeout(this.saver)
    }
  },
  autosaveDelay () {
    const { options } = this.props
    if (this.boolean() || (options != null)) {
      return 0
    }
    return 250
  },
  userEditedValueSynchronous (event, childComponent) {
    let obj
    let obj1
    let newValue

    if (typeof event === 'string') {
      newValue = event
    } else {
      newValue = this.sendableValue(event.target)
    }

    childComponent.setState({
      status: 'saving'
    })
    const axiosRequest = childComponent.state.value.id != null ? axios.patch : axios.post
    return axiosRequest(this.pathFor(childComponent.state.value.id), (
      obj = {
        _method: childComponent.state.value.id != null ? 'PATCH' : 'POST',
        id: childComponent.state.value.id
      },
      obj[`${this.model()}`] = (
        obj1 = {},
        obj1[`${this.attribute()}`] = newValue,
        obj1
      ),
      obj
    )).then((_this => ({ data: response }) => {
      let newValues
      let obj
      if (_this.disappearSaved != null) {
        clearTimeout(_this.disappearSaved)
      }
      if (_this.arrayType() != null) {
        newValues = _this.state.value.map(value => {
          if (value.componentId === childComponent.props.componentId) {
            value.id = response.id
          }
          return value
        })
        _this.setState({
          value: newValues
        })
      }
      return childComponent.setState({
        value: {
          ...childComponent.state.value,
          ...(
            obj = {
              id: response.id
            },
            obj[`${_this.attribute()}`] = response[_this.attribute()],
            obj
          )
        },
        errors: [],
        status: 'saved'
      }, () => _this.disappearSaved = setTimeout(() => {
        if (childComponent.state.status !== 'save') {
          childComponent.setState({
            status: 'inert'
          })
        }
        return _this.disappearSaved = null
      }, 2500))
    })(this)).catch(xhr => {
      const errors = xhr.responseJSON
        ? xhr.responseJSON.errors
        : ['A network error prevented this setting from being saved. Please reload the page and try again.']
      childComponent.setState({
        errors,
        status: 'inert'
      })
    })
  },
  userRemovedValue (childComponent) {
    return (_this => () => {
      if (childComponent.state.value.id != null) {
        return axios.delete(_this.pathFor(childComponent.state.value.id)).then(() => _this.removeInput(childComponent.props.componentId))
      }
      return _this.removeInput(childComponent.props.componentId)
    })(this)
  },
  removeInput (componentId) {
    const { value: ref } = this.state
    let value
    if (this.disappearSaved != null) {
      clearTimeout(this.disappearSaved)
    }
    this.disappearSaved = null
    const newValue = slice.call(function () {
      let j
      let len
      const results = []
      for (j = 0, len = ref.length; j < len; j++) {
        value = ref[j]
        if (value.componentId !== componentId) {
          results.push(value)
        }
      }
      return results
    }.call(this))
    return this.setState({
      value: newValue
    })
  },
  showRemoveButton (values) {
    let value
    return ((() => {
      let j
      let len
      const results = []
      for (j = 0, len = values.length; j < len; j++) {
        value = values[j]
        if (value.id != null) {
          results.push(value)
        }
      }
      return results
    })()).length > 1
  },
  field (type, sharedProps) {
    return React.createElement(Field, { ...sharedProps, type })
  },
  fields (sharedProps) {
    const { for: forName, type } = this.props
    let arrayType
    let classes
    let i
    let j
    let len
    let length
    let obj
    let ref
    let results
    let value
    if (arrayType = this.arrayType()) {
      length = sharedProps.initialValue.length
      classes = [(length > 1 ? 'u-mrgn-btm--5' : undefined)].join(' ').replace(/^ *| *$/g, '')
      ref = sharedProps.initialValue
      results = []
      for (i = j = 0, len = ref.length; j < len; i = ++j) {
        value = ref[i]
        results.push(this.field(arrayType[1], {
          ...sharedProps,
          key: `${forName}-input-${value.componentId}`,
          classes,
          componentId: value.componentId,
          initialValue: (
            obj = {
              id: value.id
            },
            obj[`${this.attribute()}`] = value[this.attribute()],
            obj
          ),
          inputId: sharedProps.inputId.split('_').concat(i).join('_'),
          noRemove: !this.showRemoveButton(sharedProps.initialValue),
          onRemove: this.userRemovedValue
        }))
      }
      return results
    }
    return this.field(type, sharedProps)
  },
  addInput () {
    const { value } = this.state
    let obj
    let componentId
    this.state.autofocus = true
    if (value.length > 0) {
      componentId = value[value.length - 1].componentId + 1
    } else {
      componentId = 0
    }

    return this.setState({
      value: value.concat((
        obj = { componentId },
        obj[`${this.attribute()}`] = '',
        obj
      ))
    })
  },
  inputId () {
    const { for: forName, inputId } = this.props
    return inputId || forName.split('.').join('_')
  },
  labelFor () {
    if (this.arrayType()) {
      return `${this.inputId()}_0`
    }
    return this.inputId()
  },
  label () {
    const { field, label, labelClass } = this.props
    if (field === 'radio') {
      return React.createElement('label', null, label)
    }
    return React.createElement('label', {
      className: labelClass,
      htmlFor: this.labelFor()
    }, label)
  },
  checkboxLabel () {
    const { label } = this.props
    if (this.boolean()) {
      return label
    }
  },
  labelBefore () {
    const { labelPosition } = this.props
    if (labelPosition === 'before' && !this.boolean()) {
      return this.label()
    }
  },
  labelAfter () {
    const { labelPosition } = this.props
    if (labelPosition === 'after' && !this.boolean()) {
      return this.label()
    }
  },
  addInputButton () {
    const { addLabel, noCreate } = this.props
    if ((this.arrayType() != null) && !noCreate) {
      return React.createElement('div', {
        className: ''
      }, React.createElement(AddInputButton, {
        add: this.addInput,
        label: addLabel
      }))
    }
  },
  render () {
    const {
      errors, field, fieldWrapperClass, helpers,
      name, noCreate, options, placeholder
    } = this.props
    const { autofocus, value } = this.state
    return React.createElement('div', {
      className: this.wrapperClasses()
    }, this.labelBefore(), this.fields({
      autosave: this.autosave(),
      autofocus,
      name,
      label: this.checkboxLabel(),
      field,
      model: this.boolModel(),
      attribute: this.attribute(),
      class: this.classes(),
      fieldWrapperClass,
      initialValue: value,
      placeholder,
      noCreate,
      inputId: this.inputId(),
      onEdit: this.userEditedValue,
      options,
      helpers,
      errors
    }), this.labelAfter(), this.addInputButton())
  }
})

export default Input
