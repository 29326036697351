import React, { Fragment, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import axios from '@ytb/axios'

import './css'
import FileInput from './components/file-input'
import FileList from './components/file-list'
import uploader from './helpers/uploader'
import ErrorList from './components/error-list'
import usePrevious from './helpers/use-previous-hook'
import useBeforeUnload from './helpers/use-before-unload-hook'

const EmailAttachments = ({
  initialFiles,
  attachableType,
  attachableId,
  restrictions,
  buttonLabel,
  isSelectable,
  showFileSize,
  uploadsEnabled,
  inputName
}) => {
  const [files, setFiles] = useState(initialFiles)
  const prevInitialFiles = usePrevious(initialFiles)
  const [errors, setErrors] = useState([])
  const [uploading, setUploading] = useState(false)

  const onUploadEnd = file => {
    const newFile = Object.assign(file, { selected: true })

    setErrors([])
    setFiles(currentFiles => [...currentFiles, newFile])
    setUploading(false)
  }

  const handleError = error => {
    if (!errors.includes(error)) {
      setErrors([...errors, error])
      setUploading(false)
    }
  }

  const fileUploader = useMemo(() => (
    uploader({
      fileType: 'file',
      onUploadStart: () => setUploading(true),
      onUploadEnd,
      endpoint: '/attachments',
      params: {
        attachable_id: attachableId,
        attachable_type: attachableType
      },
      restrictions,
      handleError
    })
  ), [files, errors])

  useEffect(() => {
    if (prevInitialFiles !== undefined && prevInitialFiles !== initialFiles) {
      setFiles(initialFiles)
    }

    return () => fileUploader.close
  }, [prevInitialFiles, initialFiles])

  useEffect(() => {
    document.dispatchEvent(new CustomEvent('react.mounted', { detail: 'file-uploader' }))
  }, [])

  useBeforeUnload(uploading)

  const handleDelete = file => {
    axios.delete(`/attachments/${file.id}`)
      .then(() => {
        const filteredFiles = files.filter(f => f.id !== file.id)
        setFiles([...filteredFiles])
      })
  }

  if (uploadsEnabled) {
    return (
      <>
        <div className='file-uploader file-list--reverse'>
          <FileInput
            uploader={fileUploader}
            buttonClass='btn__add btn__add--sml'
            label={buttonLabel}
            includeIcon='true'
            clearErrors={() => setErrors([])}
            uploading={uploading}
          />
          <FileList
            files={files}
            onDelete={handleDelete}
            isSelectable={isSelectable}
            showFileSize={showFileSize}
          />
        </div>
        <ErrorList errors={errors} />
      </>
    )
  }
  return (
    <FileList
      files={files}
      onDelete={handleDelete}
      isSelectable={isSelectable}
      showFileSize={showFileSize}
      inputName={inputName}
    />
  )
}

EmailAttachments.defaultProps = {
  restrictions: {},
  buttonLabel: 'Attach file(s)',
  isSelectable: true,
  showFileSize: false,
  uploadsEnabled: true,
  inputName: 'attachments',
  attachableId: null
}

EmailAttachments.propTypes = {
  initialFiles: PropTypes.array.isRequired,
  attachableType: PropTypes.string.isRequired,
  attachableId: PropTypes.number,
  restrictions: PropTypes.object,
  buttonLabel: PropTypes.string,
  isSelectable: PropTypes.bool,
  showFileSize: PropTypes.bool,
  uploadsEnabled: PropTypes.bool,
  inputName: PropTypes.string
}

export default EmailAttachments
